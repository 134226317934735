<div class="thankyou-bg privacy-policy-bg">
  <div class="logo"><a [routerLink]="['/dashboard']"><img src="./assets/images/logo.png" /></a></div>
  <div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i> Back</a></div>
  <div class="thankyou-box box-shadow privacy-policy-box">
    <div class="container">
      <div id="phoneEmail">
        <label for="Mobile" class="material-icons">
          <input type="radio" id="phoneCheck" value="Mobile" (click)="selectedOption()" name="mobile_or_desktop" checked={{phoneCheck}}>
          <span class="material-icons-outlined" style="color: #B3CEFB">
            phone_iphone
          </span>
        </label>
        <label for="Desktop" class="material-icons">
          <input type="radio" id="emailCheck" value="Desktop" (click)="selectedOption()" name="mobile_or_desktop" checked={{emailCheck}}>
          <span class="material-icons-outlined" style="color: #B3CEFB">
            desktop_windows
          </span>
        </label>
      </div>
      <div>
        <h3 style="color: black; font-size: 1.35rem">Mobile&ensp;Desktop</h3>
      </div>
    </div>
    <div id="phoneIsChecked">
      <h1 style="color: #000; margin-top: 1.5rem; "> Mobile User Guide</h1>
      <h4> Table of Contents</h4>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('iconLegend').scrollIntoView();" class="try-link">
          1.Icon legend
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('chatScreen').scrollIntoView();" class="try-link">
          2.Chat screen
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('chatRoom').scrollIntoView();" class="try-link">
          3.Chat room
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('dialPad').scrollIntoView();" class="try-link">
          4.Dial pad
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('contacts').scrollIntoView();" class="try-link">
          5.Contacts
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('meeting').scrollIntoView();" class="try-link">
          6.Meeting
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('login').scrollIntoView();" class="try-link">
          7.Login
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('callOptions').scrollIntoView();" class="try-link">
          8.Options during a call
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('changePassword').scrollIntoView();" class="try-link">
          9.Change password
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('changeEmail').scrollIntoView();" class="try-link">
          10.Change email
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('changePhone').scrollIntoView();" class="try-link">
          11.Change phone number
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('changeDisplayName').scrollIntoView();" class="try-link">
          12.Change display name
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('changePayment').scrollIntoView();" class="try-link">
          13.Change payment
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('cancelSub').scrollIntoView();" class="try-link">
          14.Cancel subscription
        </a>
      </p>
      <h4 id="iconLegend">1.Icon legend</h4>
      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Initiate one-to-one chat -
        </p>
        <a class="image-adjustment"><img src="./assets/images/one-one-chat-icon.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Initiate a group chat -
        </p>
        <a class="image-adjustment"><img src="./assets/images/group-chat-icon.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Indicates that the ephemeral timer is on -
        </p>
        <a class="image-adjustment"><img src="./assets/images/ephemeral-on-icon.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Select all items -
        </p>
        <a class="image-adjustment"><img src="./assets/images/select-all-items.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Cancel button -
        </p>
        <a class="image-adjustment"><img src="./assets/images/cancel-button.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Indicates the remaining ephemeral time left on a message, before the message gets deleted -
        </p>
        <a class="image-adjustment"><img src="./assets/images/ephemeral-time-left.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Initiate a group call or switch to scheduling a meeting -
        </p>
        <a class="image-adjustment"><img src="./assets/images/group-call-button.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Switch between Titanium™ users and non-Titanium™ users -
        </p>
        <a class="image-adjustment"><img src="./assets/images/contacts-button.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Bouncing number indicates unread messages -
        </p>
        <a class="image-adjustment"><img src="./assets/images/undread-message.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          One checkmark indicates that the message was delivered -
        </p>
        <a class="image-adjustment"><img src="./assets/images/delivered-message.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Two checkmarks indicate that the message was read, and the ephemeral timer will start the countdown -
        </p>
        <a class="image-adjustment"><img src="./assets/images/read-message.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          During a call, check the current list of all ongoing calls -
        </p>
        <a class="image-adjustment"><img src="./assets/images/calls-list-button.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          During a call, the user can start a new call which pauses the current call or transfer the call -
        </p>
        <a class="image-adjustment"><img src="./assets/images/start-new-call.png" /></a>
      </div>

      <div class="cheat-sheet-image icon-div-adjustment" style="display:inline-block">
        <p id="align-center" style="margin-right:30px">
          Clicking on the X button will remove the user from the group chat, the admin of the group can make other participants' admin, by clicking the admin button -
        </p>
        <a><img src="./assets/images/group-info-participant.png" /></a>
      </div>

      <h4 id="chatScreen">2.Chat screen</h4>
      <p id="align-left">
        - Group chat is indicated by displaying the group icon, one on one chat is indicated by displaying the initials of the user you're chating with.
      </p>
      <p id="align-left">
        - The clock icon indicates that the ephemeral timer for your messages is on.
      </p>
      <p id="align-left">
        - Create a group chat by selecting the second icon on the top left, then select the participants for the group.
      </p>
      <p id="align-left">
        - Use the trash can icon to delete a chat by selecting the chat room.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/chat-screen.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/delete-chat-room.png" /></a></div>
      <h4 id="chatRoom">3.Chat room</h4>
      <p id="align-left">
      <p id="align-left">
        - The ephemeral timer is on by default and is set to 1 hour.
      </p>
      <p id="align-left">
        - The current set ephemeral time can be seen below the user/group name.
      </p>
      <p id="align-left">
        - The ephemeral timer can be changed by clicking the three dots on the top right, then selecting the ephemeral timer.
      </p>
      <p id="align-left">
        - The timer will start the countdown once the user you're chatting with sees the message you have sent. The count down can be seen on the bottom left of your message.
      </p>
      <p id="align-left">
        - Attachments can be sent by clicking the paper clip and then selecting the source of the attachment.
      </p>
      <p id="align-left">
        - Audio messages can be sent by clicking the microphone icon on the bottom left.
      </p>
      <p id="align-left">
        - Holding on a message will pop up a menu with options to reply, delete or forward a message.
      </p>
      <p id="align-left">
        - Clicking the shield icon on the top right will display the conversation devices.
      </p>
      <p id="align-left">
        - Group info can be accessed by clicking the three dots, then select group info, it will display who is the current admin of the group and the current participants.
      </p>
      <p id="align-left">
        - The admin of the group can add users to the group, remove them or make a user admin.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/chat-room.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/ephemeral-timer-screen.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/attachment-sent.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/chat-menu.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/group-info-screen.png" /></a></div>

      <h4 id="dialPad">4.Dial pad</h4>
      <p id="align-left">
        - To start a group call, click on the bottom left icon, then enter the group call subject and select the participants for the group call.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/dial-pad-screen.png" /></a></div>

      <h4 id="contacts">5.Contacts</h4>
      <p id="align-left">
        - The top left icon displays your Titanium™ contacts.
      </p>
      <p id="align-left">
        - Contacts which are not part of Titanium can be displayed by clicking on the icon without the Titanium™ logo.
      </p>
      <p id="align-left">
        - Non-Titanium™ users can be invited to join Titanium™ by clicking the invite button, or clicking on the contact and then selecting send which phone number to send the invite.
      </p>
      <p id="align-left">
        - To create a new contact, press on the icon with the plus sign and enter the contact details.
      </p>
      <!--<div class="cheat-sheet-image"><a><img src="./assets/images/titanium-contacts.png" /></a></div>-->
      <div class="cheat-sheet-image"><a><img src="./assets/images/normal-contacts.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/contact-details.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/create-contact.png" /></a></div>

      <h4 id="meeting">6.Meeting</h4>
      <p id="align-left">
        - In the meetings tab you can view all of your scheduled meetings. Pressing the info icon will display the option to join the meeting or to edit/delete the meeting.
      </p>
      <p id="align-left">
        - To start a group call or to schedule a meeting, press the icon on the top right. If you want to schedule a meeting for later, press the toggle button and enter the meeting details, select the participants and schedule the meeting.
      </p>
      <p id="align-left">
        - The meeting info will display the meeting details and all the participants that are added to the meeting.
      </p>
      <p id="align-left">
        - All participants will receive a meeting invite.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/meeting-tab.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/meeting-tab-info.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/group-call.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/schedule-meeting.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/meeting-info.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/meeting-invite.png" /></a></div>

      <h4 id="login">7.Login</h4>
      <p id="align-left">
        - To open the side menu, press the top left button.
      </p>
      <p id="align-left">
        - To go to the login screen, press on the logged out text next to the user icon. Once there, click on sign in and enter your account details.
      </p>
      <p id="align-left">
        - Once logged in, going to the side menu, the settings tab and the dashboard will appear.
      </p>
      <p id="align-left">
        - The dashboard allows the user to manage their account.
      </p>
      <p id="align-left">
        - To logout, click on your username next to the user icon.
      </p>

      <div class="cheat-sheet-image"><a><img src="./assets/images/side-menu.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/login-screen.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/logged-in-screen.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/dashboard-screen.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/logout-screen.png" /></a></div>

      <h4 id="callOptions">8.Options during a call</h4>

      <p id="align-left">
        - During a single or group call, click on the three dots to open the option menu.
      </p>
      <p id="align-left">
        - During a call users can check the call statistics, go back to the chat screen, see the list of all participants, change their call layout or check the current list of all ongoing calls.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/call-options.png" /></a></div>

      <h4 id="changePassword">9.Change password</h4>

      <p id="align-left">
        - From the dashboard, enter the change password tab, the fields will require the old password and confirm the new password, a popup will display a confirmation of the change.
      </p>
      <p id="align-left">
        - After changing the password, the user would have to sign in again.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/change-password.png" /></a></div>

      <h4 id="changeEmail">10.Change email</h4>

      <p id="align-left">
        - From the dashboard, enter the change email tab, the fields will require your current password and the new email address, a popup will display a confirmation of the change.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/change-email.png" /></a></div>

      <h4 id="changePhone">11.Change phone number</h4>

      <p id="align-left">
        - From the dashboard, enter the change phone number tab, the fields will require your current password the new phone number, after submitting you will receive a one time password on your new phone number, after confirming the otp, a popup will display a confirmation of the change.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/change-phone-number.png" /></a></div>

      <h4 id="changeDisplayName">12.Change display name</h4>

      <p id="align-left">
        - From the dashboard, enter the change display name tab, the fields will require your current password and the new display name, a popup will display a confirmation of the change.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/change-display-name.png" /></a></div>

      <h4 id="changePayment">13.Change payment</h4>

      <p id="align-left">
        - From the dashboard, enter the change payment tab, the fields will require your to enter the new card details, a popup will display a confirmation of the change.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/change-payment.png" /></a></div>

      <h4 id="cancelSub">14.Cancel subscription</h4>

      <p id="align-left">
        - From the dashboard, enter the cancel subscription tab, two options are available, either unsubscribe and keep your account or unsubscribe and delete your account permanently, a popup will display a confirmation of the change.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/cancel-sub.png" /></a></div>
    </div>
    <!--desktop guide-->
    <div id="emailIsChecked">
      <h1 style="color: #000; margin-top: 1.5rem; "> Desktop User Guide</h1>
      <h4> Table of Contents</h4>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('iconLegend-desktop').scrollIntoView();" class="try-link">
          1.Icon legend
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('chatScreen-desktop').scrollIntoView();" class="try-link">
          2.Chat screen
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('chatRoom-desktop').scrollIntoView();" class="try-link">
          3.Chat room
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('contacts-desktop').scrollIntoView();" class="try-link">
          4.Contacts
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('meeting-desktop').scrollIntoView();" class="try-link">
          5.Meeting
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('login-desktop').scrollIntoView();" class="try-link">
          6.Login
        </a>
      </p>
      <p>
        <a routerLinkActive="active" onClick="document.getElementById('callOptions-desktop').scrollIntoView();" class="try-link">
          7.Options during a call
        </a>
      </p>

      <h4 id="iconLegend-desktop">1.Icon legend</h4>
      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Initiate one-to-one chat with the selected user -
        </p>
        <a class="image-adjustment"><img src="./assets/images/start-chat.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Initiate group chat with the selected users -
        </p>
        <a class="image-adjustment"><img src="./assets/images/start-group-chat.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Indicates current status -
        </p>
        <a class="image-adjustment"><img src="./assets/images/current-status.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Hide/show timeline -
        </p>
        <a class="image-adjustment"><img src="./assets/images/hide-timeline.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Open home screen -
        </p>
        <a class="image-adjustment"><img src="./assets/images/open-home.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Displays the number of unread messages -
        </p>
        <a class="image-adjustment"><img src="./assets/images/unread-messages.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Call the group -
        </p>
        <a class="image-adjustment"><img src="./assets/images/call-group.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Change/set the ephemeral timer -
        </p>
        <a class="image-adjustment"><img src="./assets/images/ephemeral-messages.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Schedule a meeting -
        </p>
        <a class="image-adjustment"><img src="./assets/images/create-meeting.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Displays the current ephemeral time left on the message -
        </p>
        <a class="image-adjustment"><img src="./assets/images/current-ephemeral-time.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Indicates that the next message that will be sent is ephemeral -
        </p>
        <a class="image-adjustment"><img src="./assets/images/send-ephemeral.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Indicates that the message is read -
        </p>
        <a class="image-adjustment"><img src="./assets/images/read-message-desktop.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Displays a menu with options to reply, delete or forward a message -
        </p>
        <a class="image-adjustment"><img src="./assets/images/more-options.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Indicates that you are admin of the group -
        </p>
        <a class="image-adjustment"><img src="./assets/images/admin-group.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Delete chat -
        </p>
        <a class="image-adjustment"><img src="./assets/images/delete-chat.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Display the contacts tab -
        </p>
        <a class="image-adjustment"><img src="./assets/images/contacts-tab.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Display the meetings tab -
        </p>
        <a class="image-adjustment"><img src="./assets/images/meetings-tab.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Invite participants to a meeting -
        </p>
        <a class="image-adjustment"><img src="./assets/images/invite-participants-meeting.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Transfer call -
        </p>
        <a class="image-adjustment"><img src="./assets/images/transfer-call.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Start a new call -
        </p>
        <a class="image-adjustment"><img src="./assets/images/start-new-call-desktop.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Call settings -
        </p>
        <a class="image-adjustment"><img src="./assets/images/call-settings.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Call options -
        </p>
        <a class="image-adjustment"><img src="./assets/images/call-options-desktop.png" /></a>
      </div>

      <div class="cheat-sheet-image-icon icon-div-adjustment">
        <p id="align-center">
          Share screen -
        </p>
        <a class="image-adjustment"><img src="./assets/images/share-screen.png" /></a>
      </div>

      <h4 id="chatScreen-desktop">2.Chat screen</h4>
      <p id="align-left">
        - Group chat is indicated by displaying the group icon, one on one chat is indicated by displaying the initials of the user you're chating with.
      </p>
      <p id="align-left">
        - The clock icon indicates that the ephemeral timer for your messages is on.
      </p>
      <p id="align-left">
        - Create a group chat by selecting the icon on the top right, then select the participants for the group.
      </p>
      <p id="align-left">
        - Use the trash can icon to delete a chat by right clicking on the chat room.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/chat-screen-desktop.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/chat-screen-delete-desktop.png" /></a></div>

      <h4 id="chatRoom-desktop">3.Chat room</h4>
      <p id="align-left">
      <p id="align-left">
        - The ephemeral timer is on by default and is set to 1 hour.
      </p>
      <p id="align-left">
        - The current set ephemeral time can be checked by clicking on the top right ephemeral timer icon.
      </p>
      <p id="align-left">
        - The ephemeral timer can be changed by clicking the top right ephemeral timer icon, then select the ephemeral time.
      </p>
      <p id="align-left">
        - The timer will start the countdown once the user you're chatting with sees the message you have sent. The count down can be seen on the bottom left of your message.
      </p>
      <p id="align-left">
        - Attachments can be sent by clicking the paper clip and then selecting the file of the attachment.
      </p>
      <p id="align-left">
        - Audio messages can be sent by clicking the microphone icon on the bottom left.
      </p>
      <p id="align-left">
        - Holding on a message will pop up a menu with options to reply, delete or forward a message.
      </p>
      <p id="align-left">
        - Clicking the shield icon on the top right will display the conversation devices.
      </p>
      <p id="align-left">
        - Group info can be accessed by clicking the three dots, then select group info, it will display who is the current admin of the group and the current participants.
      </p>
      <p id="align-left">
        - The admin of the group can add users to the group, remove them or make a user admin.
      </p>
      <p id="align-left">
        - To add a new line while composing a new message on the desktop app, hit SHIFT + ENTER.
      </p>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/chat-room-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/ephemeral-timer-screen-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/chat-menu-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/group-info-screen-desktop.png" /></a></div>

      <h4 id="contacts-desktop">4.Contacts</h4>
      <p id="align-left">
        - Pressing the top left icon displays your contacts.
      </p>
      <p id="align-left">
        - Contacts which are not part of Titanium™ won't display their current status.
      </p>
      <p id="align-left">
        - To create a new contact, press on the top right add contact button and enter the contact details.
      </p>
      <p id="align-left">
        - To edit contact details, select the contact and click on the pen icon.
      </p>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/contacts-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/add-contact-desktop.png" /></a></div>

      <h4 id="meeting-desktop">5.Meeting</h4>
      <p id="align-left">
        - In the meetings tab you can view all of your scheduled meetings. Pressing the info icon will display the option to join the meeting or to edit/delete the meeting.
      </p>
      <p id="align-left">
        - The meeting info will display the meeting details and all the participants that are added to the meeting.
      </p>
      <p id="align-left">
        - To start a group call, create a group from the top right start a chat room icon, add all participants and call the group, to schedule a meeting, create or enter a group, press the top right schedule a meeting icon and add all the participants, if you want to schedule a meeting for later, press the toggle button and enter the meeting details, select the participants and schedule the meeting.
      </p>
      <p id="align-left">
        - All participants will receive a meeting invite.
      </p>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/meeting-tab-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/group-call-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/schedule-meeting-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/meeting-invite-desktop.png" /></a></div>

      <h4 id="login-desktop">6.Login</h4>
      <p id="align-left">
        - To go to the login screen, press the home button located on the top left. Once there, click on sign in and enter your account details.
      </p>
      <p id="align-left">
        - Once logged in, to manage your account, click on the home button, then click on manage account, you will be redirected to the website.
      </p>
      <p id="align-left">
        - The dashboard allows the user to manage their account.
      </p>
      <p id="align-left">
        - To change your presence status, click on your phone number near the home button.
      </p>
      <p id="align-left">
        - To logout, click on the home button, then click on log out.
      </p>

      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/log-in-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/manage-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/presence-status.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/log-out-desktop.png" /></a></div>

      <h4 id="callOptions-desktop">7.Options during a call</h4>

      <p id="align-left">
        - During a single or group call, click on the three dots to open the settings menu, from there you can adjust settings, invite users or share your screen.
      </p>
      <p id="align-left">
        - Invite participants to the group or change the admin status of participants, by entering the group options from the bottom right button.
      </p>
      <p id="align-left">
        - During a call, users can put the call on hold, transfer the call or start a new call or check the current list of all ongoing calls, by pressing the top left button to open the menu.
      </p>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/call-settings-desktop.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/invite-participants.png" /></a></div>
      <div class="cheat-sheet-image-desktop"><a><img src="./assets/images/call-menu-desktop.png" /></a></div>
    </div>
  </div>
</div>
