<div class=wrapper>
  <div>
    <!--<video class="video-banner" loop="true" autoplay muted oncanplay="this.play()"
           onloadedmetadata="this.muted = true">
      <source src="./assets/videos/banner-video-short.mp4" type="video/mp4" />
    </video>-->    
    <div class="thankyou-box box-shadow privacy-policy-box">
      <div class="logo"><a [routerLink]="['/signup']"><img src="./assets/images/logo.png" /></a></div>
      <div id="text-color">
        <h3>AGREEMENT TO THESE TERMS OF SERVICE</h3>
        <p>This document and these terms (“Agreement”) represent a legal contract between you and Titanium Secure (“Titanium Secure“, “we“, “our“), governing your use of Titanium Secure, a mobile or web services, or mobile or web software (“Titanium Secure”) owned, controlled or offered by Titanium Secure (collectively, the ” Titanium Secure Services“). PLEASE READ CAREFULLY THE FOLLOWING TERMS AND CONDITIONS OF SERVICE. BY USING THE Titanium Secure SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THE TERMS OF THIS TERMS OF SERVICE. IF YOU DO NOT AGREE TO THIS TERMS OF SERVICE, PLEASE CEASE USING THE Titanium Secure SERVICES IMMEDIATELY.</p>
        <p>You acknowledge and agree that this contract is only between you and Titanium Secure and not any of Titanium Secure’s partners, distributors, promoters or service providers (collectively, “Distributors”). Should you have any issue or claim with respect to the Titanium Secure Services, Titanium Secure, and not its Distributors, will be solely responsible for addressing the issue or claim. If you have obtained the Titanium Secure Services from one of Titanium Secure’s Distributors, you agree that any such Distributor will have no obligation or responsibility to provide you any warranty, maintenance and support services with respect to the Titanium Secure Services.</p>
        <h4>LICENSE GRANT</h4>
        <p>All Titanium Secure Services are licensed to you and not sold. Subject to the terms of this agreement, Titanium Secure grants you a personal, non- exclusive, non-transferable and non-sublicensable license to use the Titanium Secure Services solely for your personal or internal business purposes. You may use the Titanium Secure Software for one Titanium Secure Services subscription account on up to ten mobile devices legally under your control, for your personal or internal business use. When you submit User Data to Titanium Secure, you hereby grant to Titanium Secure, a non- exclusive, royalty-free, worldwide license to distribute your User Data through the Titanium Secure Service on your behalf solely for the purpose of providing the secure messaging service. With respect to any open source or third-party code that may be incorporated in the Titanium Secure Software, such open source code is covered by the applicable open source or third-party end user license agreement, if any, authorizing use of such code. [Titanium Secure DOES NOT USE, COPY OR MODIFY USER DATA. IT’S IMPOSSIBLE FOR US TO DO THIS AS DATA IS ENCRYPTED AND WE CAN’T READ IT]</p>
        <h4>RESERVATION OF RIGHTS</h4>
        <p>The Titanium Secure Services and all content, visual interfaces, information, graphics, design, compilation, computer code, products, software, trademarks, service marks, trade names and services are the property of Titanium Secure or its subsidiaries or affiliated companies and/or third- party licensors. Except for the express license granted to you, no right, title, interest or license to the Titanium Secure Services is granted to you, whether by implication, or otherwise.</p>
        <h4>RESTRICTIONS</h4>
        <p>You acknowledge and agree that you will not: (a) reproduce or attempt to reproduce the Titanium Secure Software; (b) modify, adapt, translate or create any derivative works of the Titanium Secure Software or attempt to do the foregoing; (c) attempt to circumvent or disable the Titanium Secure Software or any technology, features or measures in the Titanium Secure Software by any means or in any manner; (d) attempt to decompile, disassemble, reverse engineer, or otherwise attempt to derive the source code for the Titanium Secure Software; (e) distribute, encumber, sell, rent, lease, sublicense, or otherwise transfer, publish or disclose the Titanium Secure Software to any third party; (f) use for a service bureau or otherwise commercialize or attempt to commercialize use of the Titanium Secure Software; or (g) remove or alter any trademark, logo, copyright or other proprietary notices, legends, symbols or labels in or on the Titanium Secure Software or used in connection with the Titanium Secure Software.</p>
        <h4>USER DATA</h4>
        <p>The Titanium Secure Services may allow the submission of content and materials by you (” User Data “), and the hosting, storing of such User Data. You shall be solely responsible for your own User Data and the consequences of storing or transmitting them. Titanium Secure assumes no responsibility whatsoever in connection with or arising from User Data. Titanium Secure does not endorse and has no control over the content of User Data submitted by other Users. Titanium Secure reserves the right to prevent you from submitting User Data and to restrict or remove User Data for any reason at any time.</p>
        <h4>PRIVACY POLICY; COPPA Compliance; GUIDELINES TO INDIVIDUAL FEATURES AND SERVICES</h4>
        <p>Titanium Secure’s Privacy Policy is hereby incorporated into this Agreement by reference. Please read this Privacy Policy carefully for disclosures relating to the collection, use, and disclosure of your personal information. By using this service, you consent to Titanium Secure’s collection and use of User Data as described in the policy in place at the time when data is collected.</p>
        <p>When using Titanium Secure, you will be subject to any additional posted guidelines or rules applicable to certain features, which may be posted from time to time (the ”Guidelines“), which are also hereby incorporated by reference into this Agreement.</p>
        <h4>MODIFICATION OF THIS AGREEMENT</h4>
        <p>Titanium Secure may modify this Terms of Service from time to time. Any and all changes to this Agreement will be posted on the TitaniumSecure.io site, and you agree to be bound by any changes to the Terms of Service when you continue to use the Titanium Secure Services after those changes are posted.</p>
        <h4>ACCOUNT INFORMATION</h4>
        <p>Titanium Secure will not be liable for any damages or liability resulting from your account information. You agree not to sell or transfer or allow another person to access your account password or Titanium Secure Services account.</p>
        <h4>SUBSCRIPTION INFORMATION</h4>
        <p>Unless otherwise noted each subscriber will receive a 30-day free subscription after submitting valid payment information. Subscriber’s credit or debit card will be charged the subscription rate for the level of service they choose less any pre-determined discounts given by specific discount codes entered at the time of registration for subscription on the thirty first day after the date the subscription is purchased and each month thereafter unless the subscriber cancels the subscription before the date of the next month’s subscription charge is processed. The account will remain active until the current month’s subscription expires. No refunds or pro-rated subscription fees will be offered.</p>
        <h4>USAGE RULES; PROHIBITED CONDUCT & USES</h4>
        <p>Titanium Secure was founded upon the belief that private communications are a universal human right, and our Services are designed to enable secure end-to-end communication. While we don’t have the technical capability to monitor Titanium Secure Services accounts or our users’ messages, we reserve the right to suspend or terminate accounts if we learn they violate the following terms.</p>
        <p><strong>MAY NOT USE THE TITANIUM SECURE SERVICES:</strong></p>
        <ul>
          <li>for illegal purposes or in furtherance of illegal activities;</li>
          <li>to engage in targeted harassment, bullying, or threats of violence against others;</li>
          <li>
            to send or disseminate other people’s private and confidential information, such as credit card numbers or Social Security/National Identity numbers, without their express
            authorization and permission;
          </li>
          <li>in any manner that infringes patents, trademarks, trade secrets, copyrights, or any other proprietary or intellectual property rights; or</li>
          <li>for the purpose of spamming others.</li>
          <li>to impersonate others in a manner that does or is intended to mislead, confuse, or deceive others;</li>
          <li>to remove, circumvent, disable, damage or otherwise interfere with security features of the Titanium Secure Services, including any technical measures we may use to prevent or restrict unauthorized access to the Titanium Secure Services, features that prevent or restrict use or copying of any content accessible through the Titanium Secure Services, or features that enforce limitations on use of the Titanium Secure Services;</li>
          <li>to intentionally interfere with or damage operation of the Titanium Secure Services or any user’s enjoyment of them, including by uploading or disseminating viruses, worms, or other malicious code;</li>
          <li>to use any robot, spider, scraper or other automated means to access the Titanium Secure Services without our express written permission;</li>
          <li>to modify the Titanium Secure Services in any manner or form; or</li>
          <li>to sell, transfer or allow another person to access your account password or Titanium Secure Services account.</li>
        </ul>
        <h4>THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS</h4>
        <p>The Titanium Secure Services may include links to other web sites or services solely as a convenience to Users. Titanium Secure does not endorse or make any representations regarding any such linked sites or the any information or materials accessible through other linked sites. Titanium Secure disclaims all liability relating to your use of such linked sites.</p>
        <h4>GOVERNMENT END USERS.</h4>
        <p>If this Titanium Secure Software is being acquired on behalf of the United States Government, then the following provision applies. Use, duplication, or disclosure of the Titanium Secure Software by the U.S. Government is subject to restrictions set forth in this Agreement and as provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR 52.227-19, or FAR 52.227-14 (ALT III), as applicable.</p>
        <h4>EXPORT CONTROL.</h4>
        <p>The Titanium Secure Software originates in the United States and is subject to United States export laws and regulations. The Titanium Secure Software may not be exported or re-exported to certain countries or those persons or entities prohibited from receiving exports from the United States. In addition, the Titanium Secure Software may be subject to the import and export laws of other countries. You agree to comply with all United States and foreign laws related to use of the Titanium Secure Software and the Titanium Secure Services.</p>
        <h4>VIOLATIONS; TERMINATION</h4>
        <p>You agree that Titanium Secure may terminate or suspend your access to the Titanium Secure Services without prior notice and without liability if such termination or suspension is based on (a) Titanium Secure’s good faith belief you have violated any of the terms and conditions of this Agreement, (b) Titanium Secure’s determination that you repeatedly infringe or have infringed the copyrights of others, (c) extended periods of inactivity with respect to any of the Titanium Secure Services. These remedies are in addition to any other remedies Titanium Secure may have at law or in equity. Titanium Secure reserves the right to terminate Free Accounts and free access to Titanium Secure Services at any time, with or without notice without any liability of any kind.</p>
        <p>Titanium Secure reserves the right to terminate any account considered as squatting on a username, or accounts that are deemed as spammers, or violate any laws of the U.S. Titanium Secure may terminate or suspend its services at any time, and for any reason without any liability.</p>
        <h4>CONSUMER RIGHTS</h4>
        <p>You may have the benefit of consumer guarantees and warranties given under statute (“Mandatory Terms”). Where any statute applies to this Agreement to give you the benefit of Mandatory Terms, and that statute does not permit Titanium Secure to exclude or limit the application of those Mandatory Terms (or would render void any attempt to do so), then those Mandatory Terms apply to this Agreement for your benefit, and nothing in this Agreement excludes or limits those Mandatory Terms or liability for breach of them. This applies to all of the terms of this Agreement, including any terms in relation to DISCLAIMERS: NO WARRANTIES, INDEMNIFICATION: HOLD HARMLESS, AND LIMITATION OF LIABILITY AND DAMAGES.</p>
        <h4>DISCLAIMERS; NO WARRANTIES</h4>
        <p>THE Titanium Secure SERVICES AND ANY THIRD-PARTY SOFTWARE, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONNECTION WITH THE Titanium Secure SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Titanium Secure, AND ITS SUPPLIERS AND PARTNERS, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT OF PROPRIETARY RIGHTS. Titanium Secure AND ITS SUPPLIERS AND PARTNERS DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE Titanium Secure SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE Titanium Secure SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF HARMFUL COMPONENTS OR THAT THE Titanium Secure SERVICES WILL MEET YOUR REQUIREMENTS. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR EQUIPMENT OR DEVICE, LOSS OF USE, OR LOSS OF DATA.</p>
        <h4>INDEMNIFICATION; HOLD HARMLESS</h4>
        <p>You agree to indemnify, defend, and hold harmless Titanium Secure, its affiliated companies, and its suppliers and partners from any and all claims, suits, actions, losses, costs, damages, and any other liabilities, including attorneys’ fees, arising out of or related to (a) your use or misuse of the Titanium Secure Services, (b) any violation of the rights of any other person or entity by you, or (c) any breach or violation by you of this Agreement. Titanium Secure reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.</p>
        <h4>LIMITATION OF LIABILITY AND DAMAGES</h4>
        <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, Titanium Secure OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS OR SUPPLIERS WILL NOT BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, RELIANCE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES RELATING TO YOUR USE OF THE Titanium Secure SERVICES, EVEN IF Titanium Secure HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <h4>MISCELLANEOUS</h4>
        <p>This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior or contemporaneous oral or written understandings. This Agreement may be amended only by a writing signed by both parties.</p>
        <p>This Agreement will be governed by the laws of the State of South Dakota, without regard to conflicts of law provisions, and you consent to the exclusive jurisdiction of the state and federal courts sitting in South Dakota, U.S. This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods. Either Titanium Secure or you may demand that any dispute or claim between Titanium Secure and you about or involving the Titanium Secure Services must be settled by arbitration utilizing the dispute resolution procedures of the American Arbitration Association (” AAA “) in San Francisco, California, USA and, if so demanded by Titanium Secure or you, both parties hereby acknowledge and agree to submit exclusively to the jurisdiction of the AAA in San Francisco, CA to resolve the applicable dispute or claim; provided that the foregoing shall not prevent Titanium Secure from seeking injunctive relief in a court of competent jurisdiction.</p>
        <p>If any provision of this Agreement should be held invalid or unenforceable by a court of competent jurisdiction, such provision will be modified to the extent necessary to render it enforceable without losing its intent or severed from this Agreement if no such modification is possible, and other provisions of this Agreement will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. This Agreement and related Guidelines, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned or transferred by Titanium Secure without restriction. The provisions of this Agreement that require or contemplate performance after the termination of this Agreement and all provisions relating to limitation of liability, disclaimers, and indemnification will be enforceable notwithstanding such termination. Neither party will be in default or be liable for any delay, failure in performance, or interruption of service resulting directly or indirectly from any cause beyond its reasonable control. If any dispute arises under this Agreement, the prevailing party will be reimbursed by the other party for any and all legal fees and costs associated therewith.</p>
      </div>
    </div>
  </div>
</div>
