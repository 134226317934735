<div class="wrapper">
    <div class="payment-header">
        <div class="payment-logo">
            <img src="./assets/images/logo.png" />
            <h3>Change Email</h3>
        </div>
    </div>
    <section class="map-form-section login-form">
       
        <!-- <div class="social-icons">
          <ul>
              <li>
                  <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                  <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                  <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
              </li>
              <li>
                  <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
              </li>
          </ul>
      </div> -->
      <div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
        <div style="padding-bottom: 0px;padding-top: 0px;" class="map-form">
            <div class="login-form-group" [@slideInOut]>
                <h3 class="addedMargin">Amount</h3>
                <h3 class="topText">Please enter your password and new email.</h3>
                <div class="form-group">
                    <div id="emailOrPhoneCheck">
                        <form autocomplete="on" [formGroup]="changeEmailForm" id="signup-form">
                          <ul>
                            <li>
                              <!-- <p style="text-align: center;">Password</p> -->
                              <input *ngIf="!showOTP" id="pass"
                                     [ngClass]="( submit && f['password']?.errors?.required ? 'form-error' : '')"
                                     type="password" formControlName="password" name="password"
                                     placeholder="Current password" />
                              <span *ngIf="!showOTP" id="shpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                                    onClick="viewPassword()"></span>

                            </li>
                            <li>
                              <!-- <p style="text-align: center;">New Email</p> -->
                              <input *ngIf="!showOTP" id="cpass"
                                     [ngClass]="( submit && f['new_email']?.errors?.required ? 'form-error' : '')"
                                     type="email" formControlName="new_email" name="new_email"
                                     placeholder="New Email" />


                            </li>
                            <li *ngIf="showOTP">
                              <h6>Verify by Phone OTP</h6>
                              <p>
                                Enter the 4 digit OTP sent to:
                                <strong>{{sendOtpToEmail}}</strong>
                              </p>

                              <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                             (fill)="handleFillEvent($event)"></ngx-otp-input>
                              <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
    type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                              <span *ngIf="showResendOTP" class="resend-otp-text pointer" (click)="resendOTP()">
                                Resend OTP
                              </span>
                              <span *ngIf="!showResendOTP" class="resend-otp-text">
                                Re-send otp in {{counter|formatTime}}
                              </span>
                            </li>
                            <li *ngIf="error && showOTP" class="show__alert__message">
                              <div class="alert alert-danger">
                                {{error}}
                              </div>
                            </li>
                            <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                              <div class="alert alert-danger">
                                {{error}}
                              </div>
                            </li>
                            <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                              <div class="alert alert-success">
                                {{success}}
                              </div>
                            </li>
                            <li *ngIf="passwordNotMatchError && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                              <div class="alert alert-danger">
                                {{passwordNotMatchError}}
                              </div>
                            </li>
                            <li>
                            <input *ngIf="!showOTP" type="button" style="margin-top: 10px;margin-bottom: 10px;" class="btn btn-orange submitBtn" [disabled]="f.new_email.invalid || f.password.invalid" (click)="verifyUserSubscription()" name="submit" [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get One Time Password'" />
                            <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkEmailOTP()" name="submit" value="Confirm" />
                            </li>
                          </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
