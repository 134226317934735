<div class="header-master desktop-header">
    <header class="header-container">
        <div class="header-container__left">
            <img routerLink="/" class="large" src="assets/images/logo.png" alt="Company Logo">

        </div>
        <div class="header-container__middle">
            <h1
                style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; padding: 0 !important; margin-bottom: 0 !important;">
                Titanium Secure Messaging</h1>
        </div>
        <div class="header-container__right">
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/service-aggrement' ]"
                routerLinkActive="active" class="btn btn-blue">Terms Of Use</a>
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/privacy-policy' ]" routerLinkActive="active"
                class="btn btn-blue">Privacy Policy</a>
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" (click)="scrollToContacts();" routerLinkActive="active"
                class="btn btn-blue">Contact Us</a>
        </div>
    </header>
    <header class="header-container">
        <div class="header-container__left">
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/about-us' ]" routerLinkActive="active"
                class="btn btn-orange banner-btn">About us</a>
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" (click)="scrollToPlans();" routerLinkActive="active"
                class="btn btn-orange banner-btn">Subscription Plans</a>    
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" (click)="scrollToDownloads();" routerLinkActive="active"
                class="btn btn-orange banner-btn">Downloads</a>
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/cheat-sheet' ]" routerLinkActive="active"
                class="btn btn-orange banner-btn">FAQ/User Guide</a>
        </div>
        <div class="header-container__middle">


        </div>
        <div class="header-container__right">
            <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/login' ]" routerLinkActive="active"
                class="btn btn-orange banner-btn">Manage Account</a>
        </div>
    </header>
</div>
<div class="header-master mobile-header">
    <header class="header-container">
        <div class="header-container__left">
        </div>
        <div class="header-container__middle">
            <!-- Hamburger Icon -->
            <h3
                style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; padding: 0 !important; margin-bottom: 0 !important;">
                Titanium Secure Messaging</h3>
        </div>
        <div class="header-container__right">
            <!-- <div class="hamburger-icon" (click)="toggleMenu()">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="menu-items" [ngClass]="{ 'show': isMenuVisible }">
                <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom" (click)="scrollToPlans();" routerLinkActive="active"
                    class="btn btn-orange banner-btn">Subscription Plans</a>
                <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/service-aggrement' ]"
                    routerLinkActive="active" class="btn btn-orange banner-btn">Terms Of Use</a>
                <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/privacy-policy' ]"
                    routerLinkActive="active" class="btn btn-orange banner-btn">Privacy Policy</a>
                <a style="padding-right: 10px; margin-right: 10px;" data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom" (click)="scrollToContacts();" routerLinkActive="active"
                    class="btn btn-orange banner-btn">Contact Us</a> -->
                    <div class="menu-btn" (click)="toggleMenu();">
                        <div class="btn-line"></div>
                        <div class="btn-line"></div>
                        <div class="btn-line"></div>
                      </div>

                      <div class="menu-items" [class.show-menu]="isMenuOpen">
                        <img routerLink="/" class="small" src="assets/images/logo.png" alt="Company Logo">
                        <a style="padding-right: 10px; margin-right: 10px; margin-left:10px;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/about-us' ]" routerLinkActive="active"
                        class="btn btn-orange banner-btn">About us</a>
                    <a style="padding-right: 10px; margin-right: 10px; margin-left:10px;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" (click)="scrollToPlans();" routerLinkActive="active"
                        class="btn btn-orange banner-btn">Subscription Plans</a>    
                    <a style="padding-right: 10px; margin-right: 10px; margin-left:10px;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" (click)="scrollToDownloads();" routerLinkActive="active"
                        class="btn btn-orange banner-btn">Downloads</a>
                    <a style="padding-right: 10px; margin-right: 10px; margin-left:10px;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/cheat-sheet' ]" routerLinkActive="active"
                        class="btn btn-orange banner-btn">FAQ/User Guide</a> 
                        <a style="padding-right: 10px; margin-top:20px; margin-right: 10px; margin-left:10px;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/service-aggrement' ]"
                        routerLinkActive="active" class="btn btn-blue">Terms Of Use</a>
                    <a style="padding-right: 10px; margin-top:20px;margin-right: 10px; margin-left:10px;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/privacy-policy' ]" routerLinkActive="active"
                        class="btn btn-blue">Privacy Policy</a>
                    <a style="padding-right: 10px; margin-top:20px; margin-right: 10px; margin-left:10px; margin-bottom: 20px;;" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" (click)="scrollToContacts();" routerLinkActive="active"
                        class="btn btn-blue">Contact Us</a>         
                      </div>
  
            <!-- </div> -->
        </div>
    </header>
</div>