<div class="wrapper">
  <section class="map-form-section login-form">
    <!-- <div class="social-icons">
        <ul>
            <li>
                <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
                <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
                <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
                <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
        </ul>
    </div> -->

    <div class="login-bg">
      <img class="pointer" (click)="goTOHome()" src="./assets/images/logo.png" />
    </div>

    <!-- <div class="map-form">
        <div class="login-form-group">
            <h3>Sign In</h3>
            <p>See your growth and get consulting support!</p>
            <div class="form-group">
                <form [formGroup]="loginForm" id="contact-form">
                    <ul>
                        <li>
                            <label>Email Address</label>
                            <input type="email" formControlName="email_id" name="email_id" placeholder="email@website.com" />
                        </li>
                        <li>
                            <label>Password </label>
                            <input [type]="show ? 'text' : 'password'" formControlName="password" name="Password" placeholder="*********" />
                            <div class="show-password" [ngClass]="{'hide-password': show === true}" (click)="showPassword()" >
                                <i class="fas fa-eye"></i>
                                <i class="fas fa-eye-slash"></i></div>
                        </li>
                        <li class="checkbox-block">
                            <div>
                                <input type="checkbox" name="remember_me" />
                                <label>Remember Me</label>
                            </div>
                            <div class="forgot-text"><a href="#">Forgot Password</a></div>
                        </li>
                        <li *ngIf="error" class="show__alert__message">
                            <div class="alert alert-danger">
                                {{error}}
                            </div>
                        </li>
                        <li>
                            <input type="button" class="btn btn-orange submitBtn" (click)="login()" name="submit" value="Sign In" />
                        </li>
                        <li class="dont-account-link">
                            <p>Don't have an account? <a [routerLink]="[ '/signup' ]" routerLinkActive="active">Sign Up</a></p>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    </div> -->

    <div class="map-form" *ngIf="steps == 1">
      <div class="login-form-group">
        <h3>Sign In</h3>
        <p>Log in here to manage your Titanium™ account. To send messages, log into the mobile or desktop app.</p>
        <div class="form-group">
          <h6 *ngIf="!showOTP">Please select your sign-in method :</h6>
          <div id="flex" class="form-control">
            <div id="phoneIsChecked" style="display:none">
              <form autocomplete="off" [formGroup]="loginForm" id="contact-form">
                <ul>
                  <li class="country-dropdown" *ngIf="!showOTP">
                    <!--<label>Phone Number</label>-->
                    <ngx-intl-tel-input
                      [ngClass]="( f['phone_number'].touched && f['phone_number']?.errors?.required || f['phone_number'].touched && f['phone_number']?.errors?.validatePhoneNumber ? 'form-error' : '')"
                      [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true" [customPlaceholder]="'Phone Number'" [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="phone_number">
                    </ngx-intl-tel-input>
                  </li>
                  <li *ngIf="f.phone_number.touched && f.phone_number.invalid">
                    <div *ngIf="f.phone_number.hasError('required') || f.phone_number.hasError('validatePhoneNumber')">
                      <div class="text-danger">
                        Invalid Phone Number!
                      </div>
                    </div>
                  </li>
                  <li *ngIf="showOTP">
                    <h6>Verify by Phone OTP</h6>
                    <p>
                      Enter the 4 digit OTP sent to:
                      <strong>+{{phone_number}}</strong>
                    </p>

                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                      (fill)="handleFillEvent($event)"></ngx-otp-input>
                    <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
          type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                    <span *ngIf="showResendOTP" style="margin-top: 10px;margin-bottom: 10px;"
                      class="resend-otp-text pointer" (click)="resendOTP()">
                      Resend OTP
                    </span>
                    <span *ngIf="!showResendOTP" style="margin-top: 10px;margin-bottom: 10px;" class="resend-otp-text">
                      Re-send otp in {{counter|formatTime}}
                    </span>
                  </li>
                  <li *ngIf="error && showOTP" class="show__alert__message">
                    <div class="alert alert-danger">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-danger" *ngIf="!showOTP">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-success">
                      {{success}}
                    </div>
                  </li>
                  <li>
                    <!-- <button class="get-otp-btn" type="button" (click)="verifyUserSubscription()">{{windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'}}</button>    -->
                    <input *ngIf="!showOTP" type="button" class="btn btn-blue submitBtn"
                      [disabled]="f.phone_number.invalid" (click)="verifyUserSubscription()" name="submit"
                      [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get One Time Password'" />
                    <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()"
                      name="submit" value="Continue" />
                  </li>
                </ul>
              </form>
            </div>
            <div id="emailIsChecked" style="display: none; margin-bottom: -17px;">
              <form autocomplete="off" [formGroup]="loginForm" id="contact-form">
                <ul>
                  <!--<h6>Enter your Email Address :</h6>-->
                  <li *ngIf="!showOTP">
                    <input
                      [ngClass]="(f['email_id']?.errors?.required || f['email_id']?.errors?.email ? 'form-error' : '')"
                      type="email" formControlName="email_id" name="email_id" placeholder="Email Address" email />
                  </li>
                  <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-danger">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="success" class="show__alert__message">
                    <div class="alert alert-success">
                      {{success}}
                    </div>
                  </li>
                  <li *ngIf="!showOTP">
                    <input type="button" class="btn btn-orange submitBtn" [disabled]="f.email_id.invalid"
                      (click)="verifyUserSubscription()" name="submit" value="Get One Time Password" />
                  </li>
                  <li *ngIf="showOTP">
                    <h6>Verify by Email Verification</h6>
                    <p>
                      Enter the 4 digit verification code sent to:
                      <strong>{{f['email_id'].value}}</strong>
                    </p>

                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                      (fill)="handleFillEvent($event)"></ngx-otp-input>
                    <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
          type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                    <span *ngIf="showResendOTP" style="margin-top: 10px;margin-bottom: 10px;"
                      class="resend-otp-text pointer" (click)="resendOTP()">
                      Resend OTP
                    </span>
                    <span *ngIf="!showResendOTP" style="margin-top: 10px;margin-bottom: 10px;" class="resend-otp-text">
                      Re-send otp in {{counter|formatTime}}
                    </span>
                  </li>
                  <li *ngIf="error && showOTP" class="show__alert__message">
                    <div class="alert alert-danger">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="success" class="show__alert__message">
                    <div class="alert alert-success">
                      {{success}}
                    </div>
                  </li>
                  <li>
                    <!-- <button class="get-otp-btn" type="button" (click)="verifyUserSubscription()">{{windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'}}</button>    -->
                    <!--<input *ngIf="!showOTP" type="button" class="btn btn-blue submitBtn" (click)="verifyUserSubscription()" name="submit" [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'" />-->
                    <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkEmailOTP()"
                      name="submit" value="Continue" />
                  </li>
                </ul>
              </form>
            </div>
            <div class="container">
              <div id="phoneEmail" *ngIf="!showOTP">
                <label for="Phone" class="material-icons">
                  <input type="radio" id="phoneCheck" value="Phone" (click)="selectedOption()"
                    onclick="document.getElementById('contact-form').reset()" name="phone_or_email"
                    checked={{phoneCheck}}>
                  <span class="material-icons-outlined" style="color: #B3CEFB">
                    phone_iphone
                  </span>
                </label>
                <label for="Email" class="material-icons">
                  <input type="radio" id="emailCheck" value="Email" (click)="selectedOption()"
                    onclick="document.getElementById('contact-form').reset()" name="phone_or_email"
                    checked={{emailCheck}}>
                  <span class="material-icons-outlined" style="color: #B3CEFB">
                    mail_outline
                  </span>
                </label>
              </div>
            </div>
            <!--<div id="phoneEmail" *ngIf="!showOTP">
              Email <input type="radio" id="emailCheck" value="Email" (click)="selectedOption()" onclick="document.getElementById('contact-form').reset()" name="phone_or_email" checked={{emailCheck}}>
              Phone <input type="radio" id="phoneCheck" value="Phone" (click)="selectedOption()" onclick="document.getElementById('contact-form').reset()" name="phone_or_email" checked={{phoneCheck}}>
            </div>-->
            <div id="sign-in-link" class="dont-account-link" *ngIf="!showOTP">
              <p style="margin-bottom:0"></p>
            </div>
          </div>

          <div id="recaptcha-container"></div>
        </div>
      </div>
      <div class="bottom-slide-icons">
        <div class="active"></div>
        <div></div>
      </div>
    </div>

    <div class="map-form" *ngIf="steps == 2">
      <div class="login-form-group">
        <h3>Sign In</h3>
        <p>Log in here to manage your Titanium™ account. To send messages, log into the mobile or desktop app.</p>
        <div class="form-group">
          <h6>Please enter your password :</h6>
          <div id="flex" class="form-control">
            <form autocomplete="off" [formGroup]="loginForm" id="contact-form">
              <ul>
                <!--<h6>Enter your Email Address :</h6>-->
                <li style="padding-bottom: 5px;">
                  <input id="pass" [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
                    type="text" formControlName="password" name="password" placeholder="Password" />   
                  <span id="shpass" class="fa fa-eye field-icon" aria-hidden="true" onClick="viewPassword()"></span>

                </li>
                <li *ngIf="passwordNotMatchError && f.password.touched" class="show__alert__message"
                  style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{passwordNotMatchError}}
                  </div>
                </li>
                <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li>
                  <input type="button" class="btn btn-orange submitBtn" [disabled]="!f.password.valid" (click)="login()"
                    name="submit" value="Sign in" />
                </li>
                <li style="padding-bottom: -5px;" class="dont-account-link">
                  <p style="margin-bottom: -3px;">Forgot Your Password? <button class="resetButton" type="button"
                      (click)="resetPasswordSendNotification()">Reset Password</button></p>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      <div class="bottom-slide-icons">
        <div class="active"></div>
        <div class="active"></div>
      </div>
    </div>
  </section>
</div>