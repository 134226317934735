<div class="wrapper" *ngIf="!ShowThankyouScreen">
  <section class="map-form-section-embedded login-form">
    <!-- <div class="social-icons">
        <ul>
            <li>
                <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
                <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
                <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
                <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
        </ul>
    </div> -->

    <div class="login-bg" style="padding-bottom: 0px;">
      <img class="pointer" src="./assets/images/logo.png" />
    </div>
    <div style="padding-bottom: 0px;padding-top: 0px;" class="map-form" *ngIf="steps == 1">
      <div class="login-form-group">
        <h4 class="try-text">Try Titanium free for 30 days</h4>
        <div style="padding-bottom: 0px;padding-top: 0px;" class="form-group">
          <h6 style="color:white;" *ngIf="!showOTP">Please select your sign up method :</h6>
          <div id="flex" class="form-control">
            <div id="phoneIsChecked" style="display:none">
              <form autocomplete="off" [formGroup]="signupForm" id="signup-form">
                <ul>
                  <li class="country-dropdown" *ngIf="!showOTP">
                    <!--<h6>Enter your Mobile Number :</h6>-->
                    <!-- <input type="text" maxlength="15" formControlName="phone_number" name="phone_number" placeholder="Phone Number" />-->
                    <ngx-intl-tel-input [ngClass]="( signSubmit && f['phone_number']?.errors?.required || signSubmit && f['phone_number']?.errors?.validatePhoneNumber ? 'form-error' : '')"
                                        [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [customPlaceholder]="'Phone Number*'" [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                                        [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="phone_number">
                    </ngx-intl-tel-input>
                  </li>
                  <li *ngIf="f.phone_number.touched && f.phone_number.invalid">
                    <div *ngIf="f.phone_number.hasError('required') || f.phone_number.hasError('validatePhoneNumber')">
                      <div class="text-danger">
                        Invalid Phone Number!
                      </div>
                    </div>
                  </li>
       
                  <li *ngIf="showOTP">
                    <h6 style="color:white;">Verify by Phone OTP</h6>
                    <p style="color:white;">
                      Enter the 4 digit OTP sent to:
                      <strong>+{{phone_number}}</strong>
                    </p>

                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                   (fill)="handleFillEvent($event)"></ngx-otp-input>
                    <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
                    type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                    <span *ngIf="showResendOTP" class="resend-otp-text pointer" (click)="resendOTP()">
                      Resend OTP
                    </span>
                    <span *ngIf="!showResendOTP" class="resend-otp-text">
                      Re-send otp in {{counter|formatTime}}
                    </span>
                  </li>
                  <li *ngIf="error && showOTP" class="show__alert__message">
                    <div class="alert alert-danger">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-danger" *ngIf="!showOTP">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-success">
                      {{success}}
                    </div>
                  </li>
                  <li>
                    <!-- <button class="get-otp-btn" type="button" (click)="verifyUserSubscription()">{{windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'}}</button>    -->
                    <input *ngIf="!showOTP" type="button" class="btn btn-blue submitBtn" [disabled]="f.phone_number.invalid" (click)="verifyUserSubscription()" name="submit" [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Next'" />
                    <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()" name="submit" value="Continue" />
                  </li>
                </ul>
              </form>
            </div>
            <div id="emailIsChecked" style="display: none; margin-bottom: -17px;">

              <form autocomplete="off" [formGroup]="signupForm" id="signup-form">
                <ul>
                  <!--<h6>Enter your Email Address :</h6>-->
                  <li *ngIf="!showOTP">
                    <input [ngClass]="( signSubmit && f['email_id']?.errors?.required || signSubmit && f['email_id']?.errors?.email ? 'form-error' : '')"
                           type="email" formControlName="email_id" name="email_id" placeholder="Email Address*" email />
                  </li>
                  <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-danger" *ngIf="!showOTP">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-success">
                      {{success}}
                    </div>
                  </li>
                  <li *ngIf="!showOTP">
                    <input type="button" class="btn btn-orange submitBtn" [disabled]="f.email_id.invalid" (click)="verifyUserSubscription()" name="submit" value="Get Email Verification" />
                  </li>
                  <li *ngIf="showOTP">
                    <h6 style="color:white;">Verify by Email Verification</h6>
                    <p style="color:white;">
                      Enter the 4 digit verification code sent to:
                      <strong>{{f['email_id'].value}}</strong>
                    </p>

                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                   (fill)="handleFillEvent($event)"></ngx-otp-input>
                    <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
                    type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                    <span *ngIf="showResendOTP" class="resend-otp-text pointer" (click)="resendOTP()">
                      Resend OTP
                    </span>
                    <span *ngIf="!showResendOTP" class="resend-otp-text">
                      Re-send otp in {{counter|formatTime}}
                    </span>
                  </li>
                  <li *ngIf="error && showOTP" class="show__alert__message">
                    <div class="alert alert-danger">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="success" class="show__alert__message">
                    <div class="alert alert-success">
                      {{success}}
                    </div>
                  </li>
                  <li>
                    <!-- <button class="get-otp-btn" type="button" (click)="verifyUserSubscription()">{{windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'}}</button>    -->
                    <!--<input *ngIf="!showOTP" type="button" class="btn btn-blue submitBtn" (click)="verifyUserSubscription()" name="submit" [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'" />-->
                    <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkEmailOTP()" name="submit" value="Continue" />
                  </li>
                </ul>
              </form>
            </div>
            <div class="container">
              <div id="phoneEmail" *ngIf="!showOTP">
                <label for="Phone" class="material-icons">
                  <input type="radio" id="phoneCheck" value="Phone" (click)="selectedOption()" onclick="document.getElementById('signup-form').reset()" name="phone_or_email" checked={{phoneCheck}}>
                  <span class="material-icons-outlined">
                    phone_iphone
                  </span>
                </label>
                <label for="Email" class="material-icons">
                  <input type="radio" id="emailCheck" value="Email" (click)="selectedOption()" onclick="document.getElementById('signup-form').reset()" name="phone_or_email" checked={{emailCheck}}>
                  <span class="material-icons-outlined">
                    mail_outline
                  </span>
                </label>
              </div>
            </div>
            <p style="font-size: 8pt; color:white; text-align: justify;">
                You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to Titanium Secure Services, LLC or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud.
            </p>
            <!--<div id="phoneEmail" *ngIf="!showOTP">
              Email <input type="radio" id="emailCheck" value="Email" (click)="selectedOption()" onclick="document.getElementById('signup-form').reset()" name="phone_or_email" checked={{emailCheck}}>
              Phone <input type="radio" id="phoneCheck" value="Phone" (click)="selectedOption()" onclick="document.getElementById('signup-form').reset()" name="phone_or_email" checked={{phoneCheck}}>
            </div>-->
            <div id="sign-in-link" class="dont-account-link">
            </div>
          </div>

          <div id="recaptcha-container"></div>
        </div>
      </div>
    </div>

    <div style="padding-bottom: 0px;padding-top: 0px;" class="map-form" *ngIf="steps == 2">
      <div class="login-form-group" [@slideInOut]>
        <div style="padding-bottom: 0px;padding-top: 0px;" class="form-group">
          <div id="emailOrPhoneCheck">
            <form autocomplete="on" [formGroup]="signupForm" id="signup-form">
              <ul>
                <li>
                  <input (keypress)="($event)" [ngClass]="( signSubmit && f['first_name']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="first_name" name="first_name" placeholder="First Name*" />

                </li>
                <li>
                  <input (keypress)="($event)" [ngClass]="( signSubmit && f['last_name']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="last_name" name="last_name" placeholder="Last Name*" />

                </li>
                <li>
                  <input [ngClass]="( signSubmit && f['display_name']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="display_name" name="display_name" placeholder="Display Name" />

                </li>
                <li style="padding-bottom: 5px;">
                  <input id="pass" [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="password" name="password" placeholder="Password* (Example: 78978aA!)" />
                  <span id="shpass" class="fa fa-eye field-icon" aria-hidden="true" onClick="viewPassword()"></span>

                </li>
                <li style="padding-bottom: 5px;">
                  <input id="cpass" [ngClass]="( signSubmit && f['confirm_password']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="confirm_password" name="confirm_password" placeholder="Confirm Password*" />
                  <span id="shcpass" class="fa fa-eye field-icon" aria-hidden="true" onClick="viewCPassword()"></span>
                  <div id="passwordMessage" style="text-align: center; color: #f4f4f4">Password must be at least 8 characters, one uppercase letter, one lowercase letter, one number, one special character.</div>
                </li>
                <li *ngIf="passwordNotMatchError" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{passwordNotMatchError}}
                  </div>
                </li>
                <li id="showPhone" *ngIf="f.email_id.touched && f.email_id.valid && !phoneOption" class="country-dropdown">
                  <!--<li class="country-dropdown">-->
                  <ngx-intl-tel-input [ngClass]="( signSubmit && f['phone_number']?.errors?.required || signSubmit && f['phone_number']?.errors?.validatePhoneNumber ? 'form-error' : '')"
                                      [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [customPlaceholder]="'Phone Number*'" [searchCountryFlag]="true"
                                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                      [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                      [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="phone_number">
                  </ngx-intl-tel-input>
                </li>
                <li *ngIf="f.phone_number.touched && f.phone_number.invalid">
                  <div *ngIf="f.phone_number.hasError('required') || f.phone_number.hasError('validatePhoneNumber')">
                    <div class="text-danger">
                      Invalid Phone Number!
                    </div>
                  </div>
                </li>
                <li id="showEmail" *ngIf="f.phone_number.touched && f.phone_number.valid && phoneOption">
                  <input [ngClass]="( signSubmit && f['email_id']?.errors?.required || signSubmit && f['email_id']?.errors?.email ? 'form-error' : '')"
                         type="email" formControlName="email_id" name="email_id" placeholder="Email Address*" email />
                </li>
                <li id="showEmail" *ngIf="f.phone_number.touched && f.phone_number.valid && phoneOption">
                  <input [ngClass]="( signSubmit && f['confirm_email']?.errors?.required || signSubmit && f['confirm_email']?.errors?.email ? 'form-error' : '')"
                         type="email" formControlName="confirm_email" name="confirm_email" placeholder="Confirm Email Address*" email />
                </li>
                <li id="showEmail" *ngIf="emailNotMatchError" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{emailNotMatchError}}
                  </div>
                </li>
                <li>
                  <input type="text" formControlName="referral_code" name="referral_code" placeholder="Promo Code" />
                </li>
                <li>
                  <input [ngClass]="( signSubmit && f['plan_name']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="plan_name" name="plan_name" placeholder="Plan Name" email />
                </li>
                <li>
                  <input type="number" formControlName="number_of_seats" name="number_of_seats" min="0" max="550" placeholder="Number of seats" />
                </li>
                <li *ngIf="errorSeats" class="show__alert__message">
                  <div class="alert alert-danger">
                    {{errorSeats}}
                  </div>
                </li>
                <li class="terms-checkbox" style="color:white;">
                  <input [ngClass]="( signSubmit && f['acceptTermsAndConditions']?.errors?.required ? 'form-error' : '')"
                         type="checkbox" formControlName="acceptTermsAndConditions" name="acceptTermsAndConditions">
                  <div style="color:white;">
                    I have read and accept the
                    <a href="https://www.titaniumsecure.io/service-aggrement-embedded" style="color:white;" target="_blank">Terms of Use</a>
                    &nbsp;and&nbsp;
                    <!--<a [routerLink]="['/privacy-policy']">Privacy Policy</a>-->
                    <a href="https://www.titaniumsecure.io/privacy-policy-embedded" style="color:white;" target="_blank">Privacy Policy</a>
                  </div>
                </li>
                <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-success">
                    {{success}}
                  </div>
                </li>
                <li>
                  <input type="button" autocomplete="off" [disabled]="!signupForm.valid || disableButton" class="btn btn-orange submitBtn" (click)="verifyUserSubscription()" name="submit" value="Continue" />
                </li>

                <li class="dont-account-link">
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div style="padding-bottom: 0px;padding-top: 0px;" class="map-form" *ngIf="steps == 3">
      <div class="login-form-group" [@slideInOut]>
        <div class="subcription-amount">
          <div class="amount">
            <div>
              <h4 *ngIf="totalSeats > 0" class="try-text">{{totalSeats}} users x ${{pricePerSeat}}</h4>
            </div>
            <div *ngIf="updated_price == 0">
              <span id="dollar_sign">$</span> <strong id="price">0.00</strong><br />
            </div>
            <div *ngIf="updated_price != 0">
              <span id="dollar_sign">$</span> <strong id="price"> {{updated_price ? updated_price : 6.00}}</strong><br />
            </div>
            <span class="amount-label" id="amount_label">Per Month.</span>
          </div>
          <div style="padding-top: 0px;padding-bottom: 0px;" class="subscription-des">
            <!--<h4 *ngIf="updated_price != 0" style="padding-bottom: 0px;" class="try-text">Try Titanium free for 30 days</h4>-->
            <h4 *ngIf="updated_price == 0" style="padding-bottom: 0px;" class="try-text">We are using your credit card for authentication purposes only! You will not be charged!</h4>
            <h4 *ngIf="individualPlan"> Titanium is a secure messaging platform that lets you connect with your people over chat, call & video calls. It works flawlessly across iOS, Android, MacOS and Windows.</h4>
            <h4 class="try-text">{{planName}}</h4>
            <h4 *ngIf="totalSeats > 0" class="try-text">Total Users: {{totalSeats}}</h4>
          </div>
        </div>
        <div style="padding-bottom: 0px;padding-top: 0px;" class="form-group">
          <form [formGroup]="creditCardForm" (ngSubmit)="onSubmit()" id="cc-form">
            <ul>
              <li>
                <input [ngClass]="( ccSubmit && ccf['cardNumber']?.errors?.required || ccSubmit && ccf['cardNumber']?.errors?.ccNumber ? 'form-error' : '')"
                       type="text" maxlength="20" type="tel" formControlName="cardNumber" #ccNumber="ccNumber" autocomplete="cc-number" ccNumber placeholder="Credit Card Number*">

                <!-- {{ccNumber.resolvedScheme$ | async}} -->
              </li>
              <li *ngIf="ccf.cardNumber.touched && ccf.cardNumber.invalid">
                <div *ngIf="ccf.cardNumber.hasError('required') || ccf.cardNumber.hasError('ccNumber')">
                  <div class="text-danger">
                    Invalid Card Number!
                  </div>
                </div>
              </li>
              <li class="card-details">
                <select formControlName="month" [ngClass]="( ccSubmit && ccf['month']?.errors?.required ? 'form-error' : '')">
                  <option value="" disabled selected>MM</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <select formControlName="year" [ngClass]="( ccSubmit && ccf['year']?.errors?.required ? 'form-error' : '')">
                  <option value="" disabled selected>YY</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                </select>
                <input [ngClass]="( ccSubmit && ccf['cardCode']?.errors?.required ? 'form-error' : '')"
                       type="text" maxlength="4" name="cardCode" formControlName="cardCode" type="tel" autocomplete="off" ccCvc placeholder="CVV*">
                <i class="fas fa-info-circle" id="cc_icon"></i>
              </li>
              <li *ngIf="ccf.month.touched && ccf.month.invalid || ccf.year.touched && ccf.year.invalid">
                <div *ngIf="ccf.month.hasError('required') || ccf.year.hasError('required')">
                  <div class="text-danger">
                    Expiration date Is Required!
                  </div>
                </div>
              </li>
              <li *ngIf="ccf.cardCode.touched && ccf.cardCode.invalid">
                <div *ngIf="ccf.cardCode.hasError('required')">
                  <div class="text-danger">
                    CVV Number Is Required!
                  </div>
                </div>
              </li>
              <li>
                <input [ngClass]="( ccSubmit && ccf['fullName']?.errors?.required ? 'form-error' : '')"
                       type="text" name="fullName" formControlName="fullName" placeholder="Full Name on Credit or Debit card*">

              </li>
              <li *ngIf="ccf.fullName.touched && ccf.fullName.invalid">
                <div *ngIf="ccf.fullName.hasError('required')">
                  <div class="text-danger">
                    Full Name Is Required!
                  </div>
                </div>
              </li>
              <li>
                <input [ngClass]="( ccSubmit && ccf['zip']?.errors?.required ? 'form-error' : '')"
                       type="text" maxlength="15" name="zip" formControlName="zip" placeholder="Postal Code*">
              </li>
              <li *ngIf="ccf.zip.touched && ccf.zip.invalid" style="padding-bottom: 5px;">
                <div *ngIf="ccf.zip.hasError('required')">
                  <div class="text-danger">
                    Postal Code Is Required!
                  </div>
                </div>
              </li>
              <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                <div class="alert alert-danger">
                  {{error}}
                </div>
              </li>

              <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                <div class="alert alert-success">
                  {{success}}
                </div>
              </li>
              <li>
                <input type="submit" [disabled]="!creditCardForm.valid" class="btn btn-orange submitBtn" name="submit" value="Get Started">
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="wrapper" *ngIf="ShowThankyouScreen && !phoneOption">
  <div class="thankyou-bg">
    <div style="padding-top: 90px;" class="logo pointer"><img src="./assets/images/logo.png" /></div>
    <div class="thankyou-box box-shadow" *ngIf="!checkConfirm">
      <img src="./assets/images/thankyou-img-white.png" />
      <p>You have subscribed to Titanium Secure. We have sent you the phone verification link to your phone number.</p>
      <span *ngIf="showResendOTP" class="btn btn-orange pointer" (click)="resendVerificationMessage()">
        Resend verification link
      </span>
      <span *ngIf="!showResendOTP" class="resend-otp-text">
        Re-send verification link in 2 minutes.
      </span>
      <div class="download-btn-group">
      </div>
    </div>
    <div class="thankyou-box box-shadow" *ngIf="checkConfirm">
      <h1 id="welcome_text">Welcome to Titanium Secure</h1>
      <div class="download-btn-group">
        <div *ngIf="checkConfirm">
          <input type="button" (click)="getStarted()" class="btn btn-orange pointer" name="getstarted" value="Get Started" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="ShowThankyouScreen && phoneOption">
  <div class="thankyou-bg">
    <div style="padding-top: 90px;" class="logo pointer"><img src="./assets/images/logo.png" /></div>
    <div class="thankyou-box box-shadow" *ngIf="!checkConfirm">
      <img src="./assets/images/thankyou-img-white.png" />
      <p>You have subscribed to Titanium Secure. We have sent you the email verification link to your email address.</p>
      <span *ngIf="showResendOTP" class="btn btn-orange pointer" (click)="resendVerificationLink()">
        Resend verification link
      </span>
      <span *ngIf="!showResendOTP" class="resend-otp-text">
        Re-send verification link in 2 minutes.
      </span>
      <div class="download-btn-group">
      </div>
    </div>
    <div class="thankyou-box box-shadow" *ngIf="checkConfirm">
      <h1 id="welcome_text">Welcome to Titanium Secure</h1>
      <div class="download-btn-group">
        <div *ngIf="checkConfirm">
          <input type="button" (click)="getStarted()" class="btn btn-orange pointer" name="getstarted" value="Get Started" />
        </div>
      </div>
    </div>
  </div>
</div>
