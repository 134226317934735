<div class="payment-header">
    <div class="payment-logo pointer">
        <img (click)="goToHome();" src="./assets/images/logo.png" />
        <h3>{{headerTitle}}</h3>
    </div>
    <!--<div class="user-block">>
        <div class="dropdown">
            <button class="buser-icon dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                    onerror="this.src='/assets/images/user-icon-img.jpg';">
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="user-name">
                     <span><a>change</a></span> 
                </div>
                <ul>
                    <li class="subscription-menu">
                        <a [routerLink]="[ '/dashboard' ]" routerLinkActive="active"><em></em>Subscription</a>
                    </li>
                    <li class="account-setting-menu">
                        <a><em></em>Account Settings</a>
                    </li>
                    <li class="logout-menu pointer">
                        <a (click)="logOut()"><em></em>Log Out</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>-->
</div>
