<div class="wrapper">
  <div class="payment-header">
    <div class="payment-logo">
      <img src="./assets/images/logo.png" />
      <h3>Change Phone Number</h3>
    </div>
  </div>
  <section class="map-form-section login-form">

    <!-- <div class="social-icons">
        <ul>
            <li>
                <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
                <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
                <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
                <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
        </ul>
    </div> -->
    <div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
    <div style="padding-bottom: 0px;padding-top: 0px;" class="map-form">
      <div class="login-form-group" [@slideInOut]>
        <h3 class="addedMargin">Amount</h3>
        <h3 class="topText"  *ngIf="!showOTP" >Please enter your password and new phone number.</h3>
        <div class="form-group">
          <div id="emailOrPhoneCheck">
            <form autocomplete="on" [formGroup]="changePhoneForm" id="signup-form">
              <ul>
                <li class="country-dropdown" *ngIf="!showOTP">
                    <!-- <p style="text-align: center;">Password</p> -->
                    <input *ngIf="!showOTP" id="pass"
                    [ngClass]="( submit && f['password']?.errors?.required ? 'form-error' : '')" type="password"
                    formControlName="password" name="password" placeholder="Password (Example: 78978aA!)" />
                  <span *ngIf="!showOTP" id="shpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                    onClick="viewPassword()"></span>
                  <!--<h6>Enter your Mobile Number :</h6>-->
                  <!-- <input type="text" maxlength="15" formControlName="phone_number" name="phone_number" placeholder="Phone Number" />-->
                  <ngx-intl-tel-input [ngClass]="( signSubmit && f['phone_number']?.errors?.required || signSubmit && f['phone_number']?.errors?.validatePhoneNumber ? 'form-error' : '')"
                                      [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [customPlaceholder]="'Phone Number'" [searchCountryFlag]="true"
                                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                      [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                      [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="phone_number">
                  </ngx-intl-tel-input>
                </li>
                <li *ngIf="f.phone_number.touched && f.phone_number.invalid">
                  <div *ngIf="f.phone_number.hasError('required') || f.phone_number.hasError('validatePhoneNumber')">
                    <div class="text-danger">
                      Invalid Phone Number!
                    </div>
                  </div>
                </li>
                <li *ngIf="showOTP">
                  <h6>Verify by Phone OTP</h6>
                  <p>
                    Enter the 4 digit OTP sent to:
                    <strong>+{{newPhoneNumber}}</strong>
                  </p>

                  <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                 (fill)="handleFillEvent($event)"></ngx-otp-input>
                  <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
    type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                  <span *ngIf="showResendOTP" class="resend-otp-text pointer" (click)="resendOTP()">
                    Resend OTP
                  </span>
                  <span *ngIf="!showResendOTP" class="resend-otp-text">
                    Re-send otp in {{counter|formatTime}}
                  </span>
                </li>
                <li *ngIf="error && showOTP" class="show__alert__message">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-success">
                    {{success}}
                  </div>
                </li>
                <li>
                  <input *ngIf="!showOTP" type="button" style="margin-top: 10px;margin-bottom: 10px;" class="btn btn-orange submitBtn" [disabled]="f.phone_number.invalid" (click)="verifyUserSubscription()" name="submit" [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get One Time Password'" />
                  <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()" name="submit" value="Confirm" />
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
