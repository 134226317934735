<div class="wrapper" *ngIf="!ShowThankyouScreen">
  <app-header [headerTitle]="'Subscription'"></app-header>
  <div class="backlink pointer"><a [routerLink]="[ '/dashboard' ]"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
  <div class="map-form login-form">
    <div class="login-form-group" [@slideInOut]>
      <h3>Amount</h3>
      <div class="subcription-amount">
        <div class="amount">
          <span>$</span> <strong> {{updated_price ? updated_price : 6.00}}</strong><br />
          <span class="amount-label">Per Month.</span>
        </div>
        <div class="subscription-des">
          Titanium is a secure messaging platform application that lets you connect with your people over chat, call & video calls. It works flawlessly across iOS, Android, and Web interfaces.
        </div>
      </div>
      <h3>Payment</h3>
      <div class="form-group">
        <form [formGroup]="creditCardForm" (ngSubmit)="onSubmit()" id="cc-form">
          <ul>
            <li>
              <input [ngClass]="( ccSubmit && ccf['cardNumber']?.errors?.required || ccSubmit && ccf['cardNumber']?.errors?.ccNumber ? 'form-error' : '')"
                     type="text" maxlength="20" type="tel" formControlName="cardNumber" #ccNumber="ccNumber" autocomplete="cc-number" ccNumber placeholder="Credit Card Number">

              <!-- {{ccNumber.resolvedScheme$ | async}} -->
            </li>
            <li *ngIf="ccf.cardNumber.touched && ccf.cardNumber.invalid">
              <div *ngIf="ccf.cardNumber.hasError('required') || ccf.cardNumber.hasError('ccNumber')">
                <div class="text-danger">
                  Invalid Card Number!
                </div>
              </div>
            </li>
            <li class="card-details">
              <select formControlName="month" [ngClass]="( ccSubmit && ccf['month']?.errors?.required ? 'form-error' : '')">
                <option value="" disabled selected>MM</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <select formControlName="year" [ngClass]="( ccSubmit && ccf['year']?.errors?.required ? 'form-error' : '')">
                <option value="" disabled selected>YY</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
                <option value="2032">2032</option>
                <option value="2033">2033</option>
              </select>
              <input [ngClass]="( ccSubmit && ccf['cardCode']?.errors?.required ? 'form-error' : '')"
                     type="text" maxlength="4" name="cardCode" formControlName="cardCode" type="tel" autocomplete="off" ccCvc placeholder="CVV">
              <i class="fas fa-info-circle"></i>
            </li>
            <li *ngIf="ccf.month.touched && ccf.month.invalid || ccf.year.touched && ccf.year.invalid">
              <div *ngIf="ccf.month.hasError('required') || ccf.year.hasError('required')">
                <div class="text-danger">
                  Expiration date Is Required!
                </div>
              </div>
            </li>
            <li *ngIf="ccf.cardCode.touched && ccf.cardCode.invalid">
              <div *ngIf="ccf.cardCode.hasError('required')">
                <div class="text-danger">
                  CVV Number Is Required!
                </div>
              </div>
            </li>
            <li>
              <input [ngClass]="( ccSubmit && ccf['fullName']?.errors?.required ? 'form-error' : '')"
                     type="text" name="fullName" formControlName="fullName" placeholder="Full Name on Credit or Debit card">

            </li>
            <li *ngIf="ccf.fullName.touched && ccf.fullName.invalid">
              <div *ngIf="ccf.fullName.hasError('required')">
                <div class="text-danger">
                  Full Name Is Required!
                </div>
              </div>
            </li>
            <li>
              <input [ngClass]="( ccSubmit && ccf['zip']?.errors?.required ? 'form-error' : '')"
                     type="text" maxlength="15" name="zip" formControlName="zip" placeholder="Postal Code">
            </li>
            <li *ngIf="ccf.zip.touched && ccf.zip.invalid">
              <div *ngIf="ccf.zip.hasError('required')">
                <div class="text-danger">
                  Postal Code Is Required!
                </div>
              </div>
            </li>
            <li *ngIf="error" class="show__alert__message">
              <div class="alert alert-danger">
                {{error}}
              </div>
            </li>

            <li *ngIf="success" class="show__alert__message">
              <div class="alert alert-success">
                {{success}}
              </div>
            </li>
            <li>
              <input type="submit" [disabled]="!creditCardForm.valid" class="btn btn-orange submitBtn" name="submit" value="Resubscribe">
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="ShowThankyouScreen">
  <div class="thankyou-bg">
    <div class="logo pointer"><img a [routerLink]="[ '/login' ]" routerLinkActive="active" src="./assets/images/logo.png" /></div>
    <div class="thankyou-box box-shadow">
      <img src="./assets/images/thankyou-img.png" />
      <p>You have resubscribed to Titanium. We are glad to have you back! click on Use Now to start using Titanium.</p>
      <div class="download-btn-group">
        <a href="https://apps.apple.com/us/app/titanium-secure-messaging/id6455696374" target="_black" class="btn btn-blue pointer"><i class="fab fa-apple"></i> iOS (Join Open Beta)</a>
        <a href="https://www.titaniumsecure.io:3000/download/getlatestapk" target="_black" class="btn btn-blue pointer"><i class="fab fa-android"></i> Android (APK)</a>
        <a href="https://play.google.com/store/apps/details?id=io.titanium" target="_black" class="btn btn-blue pointer"><i class="fab fa-google-play"></i> Android (Google Play)</a>
        <a href="https://www.titaniumsecure.io:3000/download/getlatestmac" target="_black" class="btn btn-blue pointer"><i class="fab fa-app-store-ios"></i> MacOS</a>
        <a href="https://www.titaniumsecure.io:3000/download/getlatestwin" target="_black" class="btn btn-blue pointer"><i class="fab fa-windows"></i> Windows</a>
      </div>
    </div>
  </div>
</div>
