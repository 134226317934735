<footer>
    <div>
        <div class="footer-logo" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"><img src="./assets/images/footer-logo-icon.png" /></div>
        <div class="footer-des" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            For more information about Titanium and its services, please send an email <a href="mailto:info@titaniumsecure.io">info@titaniumsecure.io</a>
            <br>
            <br>
            Titanium Secure Services, LLC
            <br>
            6106 Yellowstone Rd.
            <br>
            Cheyenne, WY 82009
            <br>
            803-820-1000
        </div>
        <div class="footer-copyright" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">&copy; 2021-2023 Titanium. All rights reserved.</div>
        <div>
          <a href="https://www.titaniumsecure.io/service-aggrement" target="_blank">Terms of Use</a>
          &nbsp;  &nbsp;
          <!--<a [routerLink]="['/privacy-policy']">Privacy Policy</a>-->
          <a href="https://www.titaniumsecure.io/privacy-policy" target="_blank">Privacy Policy</a>
          &nbsp;  &nbsp;
          <a href="https://www.titaniumsecure.io/feedback">Feedback</a>
        </div>
    </div>
</footer>
