<main-app-header></main-app-header>
<div class="thankyou-bg privacy-policy-bg">
    <div class="logo"><a [routerLink]="['/signup']"><img src="./assets/images/logo.png" /></a></div>

    <div class="thankyou-box box-shadow privacy-policy-box">
        <h1 style = "color:black; text-align: center;"> About Titanium</h1>
        <p><strong>Titanium Secure Communications </strong> is a built from the ground up with privacy and security as fundamental design elements.
              We started Titanium Secure to fill the need left by competitors Zoom, Skype, Signal, Telegram, What’s App, and AWS’
               Wikr whose security or origins with government agencies around the globe leave a customer in doubt as to the safety
                of their communications. 
        </p>
        <p>Not so with <strong>Titanium Secure. </strong> All our servers are hosted in the U.S.A.  We never see the content of your communications
             because nothing is stored on our servers and all communications—text, audio, video—are encrypted to NIST standards 
             resistant to even quantum computers.  We are the first and only company in the industry to provide this level of encryption.   
        </p>
        <p>
            <strong>Titanium Secure </strong> is a subscription service, so we validate users and prevent access by bad actors and bots. 
             Unlike the alternatives, we do not sell nor share user data.  In fact, the only data we keep are your subscriber
              information and nothing else.
        </p>
        <p><strong>Titanium Secure </strong> works on desktops (MAC or Windows) or mobile devices (iOS, Android). Features include one-to-one or 
            group voice or video calls, video conferences, texts that can be set by the sender to disappear after a custom time,
             and secure file sharing.
        </p>
        <p>
            <strong>Titanium Secure Messaging</strong> is perfect for anyone who wants private communications or who shares sensitive or protected
             information. Titanium Secure keeps your communications and transactions safe from attack. 
        </p>
        <p>
            For enterprises and MVNOs we have income opportunities for groups who refer subscribers to our platform.
        </p>

        <h2 style = "color:black;"><strong>WHEN COMMUNICATIONS NEED TO BE SECURE,   YOU CAN BE SURE WITH TITANIUM SECURE!</strong></h2>

    </div>
</div>